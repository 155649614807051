import { BACKEND_PATHS } from 'src/constants/backend-paths';
import { OrchestratorApiService } from 'src/service';
import { ExtractFnReturnType } from 'src/lib/react-query';
import { useQuery } from '@tanstack/react-query';

export type SiteAudit = {
  auditType: string;
  auditCadence: string;
  roleName: string;
  countryCode: string;
  roleId: string;
  [key: string]: any;
};

export type SiteSupplier = {
  siteId: string;
  auditType: string;
  supplierName: string;
  supplierCoupaId: string;
  isActive: boolean;
  [key: string]: any;
};

const getSiteAudits = async (filterSite: string): Promise<SiteAudit[]> => {
  const response = await OrchestratorApiService.get(
    BACKEND_PATHS.GET_SITE_AUDITS(filterSite),
  );
  const data: { siteAuditList: SiteAudit[] } = response.data;
  return data.siteAuditList;
};

export const useFetchSiteAudits = (siteId: string) => {
  return useQuery<ExtractFnReturnType<typeof getSiteAudits>>({
    queryKey: ['getSiteAudits', siteId],
    queryFn: () => getSiteAudits(siteId),
    enabled: siteId !== '',
  });
};

const getSiteSuppliers = async (
  filterSite: string,
): Promise<SiteSupplier[]> => {
  const response = await OrchestratorApiService.get(
    BACKEND_PATHS.GET_SITE_SUPPLIERS(filterSite),
  );
  const data: { siteSuppliersList: SiteSupplier[] } = response.data;
  return data.siteSuppliersList;
};

export const useFetchSiteSuppliers = (siteId: string) => {
  return useQuery<ExtractFnReturnType<typeof getSiteSuppliers>>({
    queryKey: ['getSiteSuppliers', siteId],
    queryFn: () => getSiteSuppliers(siteId),
    enabled: siteId !== '',
  });
};
