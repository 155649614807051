import React, { ReactNode, useEffect } from 'react';
import { Loader } from 'src/components/Loader';
import { useIsInternal } from 'src/hooks/use-is-internal';
import { useUserAuthorization } from 'src/features/home/api';
import { useAuthorizationStore } from 'src/store/user-authorization-store';
import { GetUserRolesResponse } from 'src/features/home/types';

export const AppAuthorization: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { data, isLoading, error } = useUserAuthorization();
  const isInternal = useIsInternal();
  const { setUserDetails } = useAuthorizationStore();

  useEffect(() => {
    const getIsUserSiteAdmin = (data: GetUserRolesResponse) => {
      let flag = false;
      data.userRoles.forEach((userRole) => {
        if (
          userRole.attributes &&
          userRole.attributes.isSiteAdminRole === 'true' &&
          userRole.status === 'APPROVED'
        ) {
          flag = true;
        }
      });
      return flag;
    };

    const getIsUserSystemAdmin = (data: GetUserRolesResponse) => {
      let flag = false;
      data.userRoles.forEach((userRole) => {
        if (
          userRole.attributes &&
          userRole.attributes.isSystemAdminRole === 'true' &&
          userRole.status === 'APPROVED'
        ) {
          flag = true;
        }
      });
      return flag;
    };

    const getIsUserSupplierManager = (data: GetUserRolesResponse) => {
      let flag = false;
      data.userRoles.forEach((userRole) => {
        if (
          userRole.attributes &&
          userRole.attributes.isSupplierManagerRole === 'true' &&
          userRole.status === 'APPROVED'
        ) {
          flag = true;
        }
      });
      return flag;
    };

    const getIsUserPurchaseOrderAuditor = (data: GetUserRolesResponse) => {
      let flag = false;
      data.userRoles.forEach((userRole) => {
        if (
          userRole.attributes &&
          userRole.attributes.isPurchaseOrderAuditor === 'true' &&
          userRole.status === 'APPROVED'
        ) {
          flag = true;
        }
      });
      return flag;
    };

    if (!isInternal.isLoading && !isLoading && data) {
      setUserDetails(
        isInternal.data,
        getIsUserSiteAdmin(data),
        getIsUserSystemAdmin(data),
        getIsUserSupplierManager(data),
        getIsUserPurchaseOrderAuditor(data),
      );
    }
    if (!isInternal.isLoading && error) {
      setUserDetails(isInternal.data, false, false, false, false);
    }
  }, [isInternal.isLoading, isInternal.data, isLoading, setUserDetails]);

  return isLoading || isInternal.isLoading ? <Loader /> : <>{children}</>;
};
