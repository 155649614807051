import React from 'react';
import Button from '@amzn/meridian/button';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import { BundledLocalizationProps } from 'src/interfaces/BundleInterface';
import { useToastStore } from 'src/store/toast-store';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import { useProcessRoleRequest } from 'src/features/access/api/process-role-request';
import { MODIFY_ACCESS_MODAL } from 'src/features/access/constants';
import { useAuthTokenPayload } from 'src/hooks';

const ModifyAccessModal: React.FC<{
  isOpen: boolean;
  onClose: any;
  role: string;
  siteId: string;
  recordId: string;
  consensus: 'APPROVED' | 'REJECTED' | 'REVOKED';
  email: string;
  refetchUserRequestedRoles: any;
  bundle: BundledLocalizationProps['bundle'];
}> = ({
  isOpen,
  onClose,
  role,
  siteId,
  recordId,
  consensus,
  email,
  refetchUserRequestedRoles,
  bundle,
}) => {
  const { addToast } = useToastStore();
  const mutation = useProcessRoleRequest();
  const { authTokenPayload } = useAuthTokenPayload();

  const handleModifyAccessClick = () => {
    mutation.mutate(
      {
        siteId: siteId,
        recordId: recordId,
        consensus: consensus,
      },
      {
        onSuccess: (data: { isSuccessful: boolean; reason: string }) => {
          addToast({
            id: recordId,
            type: data.isSuccessful ? 'success' : 'error',
            message:
              data.reason ??
              bundle.formatMessage(
                MODIFY_ACCESS_MODAL.MODIFY_REQUEST_SUCCESSFUL,
              ),
            timeout: 5000,
          });
          if (
            email === authTokenPayload.data['email'] &&
            consensus === 'REVOKED'
          ) {
            // If user revokes their own access re-evaluate their access.
            window.location.replace('/');
          } else {
            refetchUserRequestedRoles();
          }
          onClose();
        },
        onError: () => {
          addToast({
            id: recordId,
            type: 'error',
            message: bundle.formatMessage(
              MODIFY_ACCESS_MODAL.MODIFY_REQUEST_FAILURE,
            ),
            timeout: 5000,
          });
        },
      },
    );
  };

  const getActionText = () => {
    switch (consensus) {
      case 'APPROVED':
        return 'approve';
      case 'REJECTED':
        return 'reject';
      case 'REVOKED':
        return 'revoke';
    }
  };

  return (
    <Modal
      open={isOpen}
      title={bundle.formatMessage(
        MODIFY_ACCESS_MODAL.CONFIRM_MODIFY_ACCESS_TITLE,
      )}
      onClose={onClose}
    >
      <Text>
        {bundle.formatMessage(MODIFY_ACCESS_MODAL.MODIFY_ACCESS_CONFIRMATION, {
          new_status: getActionText(),
          role: role,
          email: email,
          siteId: siteId,
        })}
      </Text>
      <ModalFooter>
        <Row alignmentHorizontal="end" widths="fit">
          <Button type="secondary" onClick={onClose}>
            {bundle.formatMessage(MODIFY_ACCESS_MODAL.CANCEL)}
          </Button>
          <Button type="primary" onClick={handleModifyAccessClick}>
            {bundle.formatMessage(MODIFY_ACCESS_MODAL.CONFIRM)}
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};
export default ModifyAccessModal;
