export const MANAGER = 'Supplier Manager';
export const EMPLOYEE = 'Supplier';
export const ACTIVE = 'ACTIVE';

export enum SupplierRole {
  'Supplier Manager' = 'gat_vendor_details_page_manager',
  'Supplier Employee' = 'gat_vendor_details_page_employee',
  'Supplier' = 'gat_vendor_details_page_employee',
}

export enum UserStatus {
  'ACTIVE' = 'gat_vendor_details_page_status_active',
  'PENDING' = 'gat_vendor_details_page_status_pending',
  'INACTIVE' = 'gat_vendor_details_page_status_inactive',
}

export const VENDOR_MANAGEMENT_PAGE_STRINGS = {
  TITLE: 'gat_vendor_management_page_title',
  COMPANY_NAME: 'gat_vendor_management_page_company_name',
  COUPAID: 'gat_vendor_management_page_coupaid',
  COUNTRY: 'gat_vendor_management_page_country',

  SUPPLIER_LIST_ERROR: 'gat_vendor_management_page_supplier_list_error',

  SEARCH_SUPPLIER_PLACEHOLDER:
    'gat_vendor_management_page_search_supplier_placeholder',
  NO_SUPPLIER_ONBOARDED_MSG:
    'gat_vendor_management_page_no_supplier_onboarded_msg',
  NO_MATCH: 'gat_vendor_management_page_no_match',
  ADD_SUPPLIER_BUTTON: 'gat_vendor_management_page_add_supplier',
};

export const VENDOR_DETAILS_PAGE = {
  TITLE: 'gat_vendor_details_page_title',
  SUPPLIER_INFO_ERROR: 'gat_vendor_details_page_supplier_info_error',
  SUPPLIER_NOT_FOUND: 'gat_vendor_details_page_supplier_not_found',
  SUPPLIER_NAME: 'gat_vendor_management_page_company_name',
  COUPAID: 'gat_vendor_management_page_coupaid',
  SERVICES_TAB_TITLE: 'gat_vendor_details_page_services_tab',
  USERS_TAB_TITLE: 'gat_vendor_details_page_users_tab',
  SUPPLIER_SITE_AUDIT_ERROR:
    'gat_vendor_details_page_supplier_site_audit_error',
  SUPPLIER_SITE_AUDIT_NO_DATA:
    'gat_vendor_details_page_supplier_site_audit_no_data',
  SITE: 'gat_vendor_details_page_site',
  SERVICE: 'gat_vendor_details_page_service',
  SUPPLIER_SERVICES: 'gat_vendor_details_page_supplier_services',
  ADD_SERVICE: 'gat_vendor_details_page_add_service_button',
  DISSOCIATE_SUPPLIER_SITE_SUCCESS:
    'gat_vendor_details_page_dissociation_success',
  DISSOCIATE_SUPPLIER_SITE_FAILURE:
    'gat_vendor_details_page_dissociation_failure',
  REMOVE: 'gat_vendor_details_page_remove',
  SUPPLIER_USERS_ERROR: 'gat_vendor_details_page_supplier_users_error',
  NO_SUPPLIER_USERS_MSG: 'gat_vendor_details_page_no_supplier_users_msg',
  NO_SUPPLIER_SERVICES_MSG: 'gat_vendor_details_page_no_supplier_services_msg',
  SUPPLIER_USERS: 'gat_vendor_details_page_supplier_users',
  ADD_USER: 'gat_vendor_details_page_add_user_button',
  EMAIL: 'gat_vendor_details_page_email',
  FIRST_NAME: 'gat_vendor_details_page_first_name',
  LAST_NAME: 'gat_vendor_details_page_last_name',
  ROLE: 'gat_vendor_details_page_role',
  STATUS: 'gat_vendor_details_page_status',
  SITES: 'gat_vendor_details_page_sites',
  ACTION: 'gat_vendor_details_page_action',
  VIEW_SITES: 'gat_vendor_details_page_view_sites',
  NO_SITE_ACCESS: 'gat_vendor_details_page_no_site_access',
  MANAGER_SITES: 'gat_vendor_details_page_manager_sites',
  OFFBOARD_ACTION: 'gat_vendor_details_page_offboard',
  RESEND_ACTION: 'gat_vendor_details_page_resend_invite',
  ADD_SITE_ACTION: 'gat_vendor_details_page_add_site',
  ACTIVE_STATUS: 'gat_vendor_details_page_status_active',
  PENDING_STATUS: 'gat_vendor_details_page_status_pending',
  SORT_BY: 'gat_vendor_details_page_sort_by',
  SEARCH_USER_PLACEHOLDER: 'gat_vendor_details_page_search_user_placeholder',
  NO_MATCH: 'gat_vendor_details_page_no_match',
  USER_SITE_LIST_ERROR: 'gat_generic_data_fetch_error_message',
  SITE_REVOKE_SUCCESS: 'gat_vendor_details_page_site_revoke_success',
  SITE_REVOKE_FAILURE: 'gat_vendor_details_page_site_revoke_failure',
  RESEND_INVITE_SUCCESS: 'gat_vendor_details_page_resend_invite_success',
  RESEND_INVITE_FAILURE: 'gat_vendor_details_page_resend_invite_failure',
};

export const ADD_SUPPLIER_FORM = {
  TITLE: 'gat_add_supplier_form_title',
  SUPPLIER_COMPANY_ONBOARDING_SUCCESS:
    'gat_supplier_access_supplier_company_onboarding_success_text',
  SUPPLIER_COMPANY_ONBOARDING_FAILURE:
    'gat_supplier_access_supplier_company_failure_text',
  NAME: 'gat_supplier_access_supplier_company_name_text',
  COUPA_ID: 'gat_supplier_access_coupa_id_text',
  REQUIRED: 'gat_supplier_access_required_field_text',
  COUNTRY: 'gat_supplier_access_country_text',
  SUBMIT: 'gat_supplier_access_submit_text',
  DATA_FETCH_ERROR: 'gat_generic_data_fetch_error_message',
};

export const INVITE_USER_FORM = {
  SUCCESS: 'gat_supplier_access_supplier_user_onboarding_success_text',
  FAILURE: 'gat_supplier_access_supplier_user_onboarding_failure_text',
  TITLE: 'gat_supplier_user_invite_title',
  DATA_ERROR: 'gat_generic_data_fetch_error_message',
  REQUIRED: 'gat_required_field_text',
  INCORRECT_EMAIL: 'gat_supplier_access_incorrect_email_text',
  EMAIL: 'gat_supplier_access_email_text',
  FIRST_NAME: 'gat_supplier_access_first_name_text',
  LAST_NAME: 'gat_supplier_access_last_name_text',
  COUNTRY: 'gat_supplier_access_country_text',
  TYPE: 'gat_supplier_access_role_type_text',
  SELECT_ONE_SITE_MSG: 'gat_invite_user_form_select_one_site_msg',
  SITES: 'gat_invite_user_form_sites_text',
  SUBMIT: 'gat_invite_user_form_submit_text',
  NO_SITES_ACCESS_MSG: 'gat_invite_user_form_no_site_access_msg',
  NO_SUPPLIER_SITE_MSG: 'gat_invite_user_form_no_supplier_site_msg',
  ROLE_TYPE_TOOLTIP_TEXT: 'gat_invite_user_form_role_tooltip',
  ROLE_TYPE_TOOLTIP_TEXT_SITE_ADMIN:
    'gat_invite_user_form_role_tooltip_site_admin',
};

export const OFFBOARD_USER_MODAL = {
  TITLE: 'gat_offboard_user_form_title',
  CONFIRMATION_TEXT: 'gat_offboard_user_form_confirmation_text',
  CANCEL: 'gat_offboard_user_form_cancel_button',
  CONFIRM: 'gat_offboard_user_form_confirm_button',
  OFFBOARD_USER_SUCCESS: 'gat_offboard_user_form_success_msg',
  OFFBOARD_USER_FAILURE: 'gat_offboard_user_form_failue_msg',
};

export const ADD_SITES_TO_EMPLOYEE_MODAL = {
  TITLE: 'gat_add_site_to_employee_modal_title',
  CONFIRM: 'gat_add_site_to_employee_modal_confirm_button',
  SELECT_SITES_TEXT: 'gat_add_site_to_employee_modal_site_select_msg',
  SITE_ADDITION_SUCCESS: 'gat_add_site_to_employee_success_msg',
  SITE_ADDITION_FAILURE: 'gat_add_site_to_employee_failure_msg',
  NO_SITES_ACCESS_MSG: 'gat_add_site_to_employee_no_site_access_msg',
  NO_SUPPLIER_SITES_MSG: 'gat_add_site_to_employee_no_supplier_site_msg',
};

export const ADD_SUPPLIER_SERVICE_MODAL = {
  TITLE: 'gat_add_supplier_service_modal_title',
  SELECT_SITE: 'gat_add_supplier_service_modal_select_site',
  SELECT_SERVICE: 'gat_add_supplier_service_modal_select_service',
  CONFIRM: 'gat_add_supplier_service_modal_confirm_button',
  SUCCESS_MSG: 'gat_add_supplier_service_modal_success_msg',
  FAILURE_MSG: 'gat_add_supplier_service_modal_failure_msg',
};

export enum UserSortColumns {
  'gat_vendor_details_page_email' = 'email',
  'gat_vendor_details_page_role' = 'role',
}
