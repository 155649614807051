import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import TableSortToggle from '@amzn/meridian/table-sort-toggle';
import { TableSortDirection } from '@amzn/meridian/table/table';
import { useEffect, useState } from 'react';
import { useApprovalRequests } from '../api/get-approval-requests';
import { Loader } from 'src/components';
import Box from '@amzn/meridian/box';
import Text from '@amzn/meridian/text';
import { APPROVE_ACCESS_PAGE_STRINGS } from '../constants';
import { UserInfo } from 'src/features/home/types';
import Icon from '@amzn/meridian/icon';
import { css } from '@emotion/css';
import Column from '@amzn/meridian/column';
import Pagination from '@amzn/meridian/pagination';
import ButtonGroup, { ButtonOption } from '@amzn/meridian/button-group';
import checkCircle from '@amzn/meridian-tokens/base/icon/check-circle';
import closeCircle from '@amzn/meridian-tokens/base/icon/close-circle';
import { useAuthTokenPayload, useIsMobile } from 'src/hooks';
import {
  BundledLocalizationProps,
  LocalizationProps,
} from 'src/interfaces/BundleInterface';
import { useProfileInfoStore } from 'src/store/profile-info';
import ModifyAccessModal from 'src/features/access/components/ModifyAccessModal';
import { TableSort } from 'src/interfaces/TableSort';

const itemsPerPage = 10;

const ApprovalRequestsTableHeader = ({
  bundle,
  sort,
  onSort,
}: {
  bundle: LocalizationProps;
  sort: { sortColumn: string; sortDirection: TableSortDirection };
  onSort: any;
}) => (
  <TableRow>
    <TableCell width={'40%'}>
      <TableSortToggle sortColumn="userId" onChange={onSort} value={sort}>
        {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.USER)}
      </TableSortToggle>
    </TableCell>
    <TableCell sortColumn="role" width={'20%'}>
      <TableSortToggle sortColumn="role" onChange={onSort} value={sort}>
        {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.ROLE)}
      </TableSortToggle>
    </TableCell>
    <TableCell width={'20%'}>
      {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.ACTION)}
    </TableCell>
  </TableRow>
);

const ApprovalRequestsTableRow: React.FC<
  {
    userId: string;
    role: string;
    site: string;
    refetch: any;
    recordId: string;
    status: string;
  } & BundledLocalizationProps
> = ({ userId, role, site, refetch, recordId, status, bundle }) => {
  const isMobile = useIsMobile();
  const { authTokenPayload } = useAuthTokenPayload();
  const [isModifyAccessModalOpen, setIsModifyAccessModalOpen] = useState(false);
  const [actionType, setActionType] = useState<
    'APPROVED' | 'REJECTED' | 'REVOKED'
  >('APPROVED');
  const handleModifyAccessClick = (
    action: 'APPROVED' | 'REJECTED' | 'REVOKED',
  ) => {
    setActionType(action);
    setIsModifyAccessModalOpen(true);
  };

  return !authTokenPayload.loading ? (
    <>
      <TableRow>
        <TableCell>{userId}</TableCell>
        <TableCell>{role}</TableCell>
        <TableCell>
          <ButtonGroup>
            {status === 'PENDING' && (
              <>
                <ButtonOption
                  onClick={() => handleModifyAccessClick('APPROVED')}
                >
                  {!isMobile &&
                    bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.APPROVE)}
                  <Icon tokens={checkCircle} />
                </ButtonOption>
                <ButtonOption
                  onClick={() => handleModifyAccessClick('REJECTED')}
                >
                  {!isMobile &&
                    bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.REJECT)}
                  <Icon tokens={closeCircle} />
                </ButtonOption>
              </>
            )}
            {status === 'APPROVED' && (
              <>
                <ButtonOption
                  onClick={() => handleModifyAccessClick('REVOKED')}
                >
                  {!isMobile &&
                    bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.REVOKE)}
                  <Icon tokens={closeCircle} />
                </ButtonOption>
              </>
            )}
          </ButtonGroup>
        </TableCell>
      </TableRow>
      {isModifyAccessModalOpen && (
        <ModifyAccessModal
          isOpen={isModifyAccessModalOpen}
          onClose={() => setIsModifyAccessModalOpen(false)}
          role={role}
          siteId={site}
          recordId={recordId}
          consensus={actionType}
          email={userId}
          refetchUserRequestedRoles={refetch}
          bundle={bundle}
        />
      )}
    </>
  ) : (
    <></>
  );
};

export const ApproveAccessList: React.FC<{
  filterSite: string;
  status: string;
}> = ({ filterSite, status }) => {
  const {
    data: approvalRequests,
    isLoading: isApprovalRequestsLoading,
    error: approvalRequestsError,
    refetch,
  } = useApprovalRequests(filterSite as string, status);

  const [sortState, setSortState] = useState<TableSort<keyof UserInfo>>({
    sortColumn: 'userId',
    sortDirection: 'ascending',
  });

  const [itemCount, setItemCount] = useState(0);
  const numberOfPages = Math.ceil(itemCount / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
  const { bundle } = useProfileInfoStore();
  const onSort = ({
    sortColumn,
    sortDirection,
  }: {
    sortColumn: keyof UserInfo;
    sortDirection: TableSortDirection;
  }) => {
    setSortState({ sortColumn, sortDirection });
  };

  if (sortState.sortColumn && sortState.sortDirection) {
    approvalRequests?.userRoles?.sort((a, b) => {
      const aValue = a?.[sortState.sortColumn] ?? null;
      const bValue = b?.[sortState.sortColumn] ?? null;
      if (aValue && bValue) {
        if (aValue < bValue) {
          return sortState.sortDirection === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortState.sortDirection === 'ascending' ? 1 : -1;
        }
      }
      return 0;
    });
  }

  useEffect(() => {
    setItemCount(approvalRequests?.userRoles?.length ?? 0);
  }, [approvalRequests]);

  return (
    <>
      {isApprovalRequestsLoading ? (
        <Loader />
      ) : approvalRequestsError ? (
        <Box spacingInset={'200'}>
          <Text type="b300">
            {bundle.formatMessage(
              APPROVE_ACCESS_PAGE_STRINGS.APPROVAL_REQUESTS_ERROR,
            )}
          </Text>
        </Box>
      ) : approvalRequests?.userRoles.length == 0 ? (
        <Box spacingInset={'200'}>
          <Text type="b300">
            {bundle.formatMessage(
              APPROVE_ACCESS_PAGE_STRINGS.NO_REQUESTS_FOUND,
            )}
          </Text>
        </Box>
      ) : (
        <div
          className={css`
            overflow-x: auto;
            max-width: 100%;
          `}
        >
          <Table
            rowComponents={[
              ApprovalRequestsTableHeader,
              ApprovalRequestsTableRow,
            ]}
            showDividers={true}
            showStripes={true}
            headerRows={1}
            headerColumns={1}
          >
            {approvalRequests?.userRoles?.length && (
              <ApprovalRequestsTableHeader
                sort={{
                  sortColumn: sortState.sortColumn,
                  sortDirection: sortState.sortDirection,
                }}
                onSort={onSort}
                bundle={bundle}
              />
            )}{' '}
            {approvalRequests?.userRoles
              ?.slice(firstVisibleIndex, lastVisibleIndex)
              .map((approvalInfo: UserInfo) => (
                <ApprovalRequestsTableRow
                  key={
                    approvalInfo.userId +
                    approvalInfo.role +
                    approvalInfo.roleId
                  }
                  userId={approvalInfo.userId}
                  role={approvalInfo.role}
                  refetch={refetch}
                  recordId={approvalInfo.recordId}
                  bundle={bundle}
                  site={filterSite}
                  status={status}
                />
              ))}
          </Table>
          <Column alignmentHorizontal="end">
            <Pagination
              showSkipArrows={true}
              numberOfPages={numberOfPages}
              onChange={setCurrentPage}
              currentPage={currentPage}
            />
          </Column>
        </div>
      )}
    </>
  );
};
