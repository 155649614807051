import { ExtractFnReturnType } from 'src/lib/react-query';
import { useQuery } from '@tanstack/react-query';
import { OrchestratorApiService } from 'src/service';
import { BACKEND_PATHS } from 'src/constants/backend-paths';
import { GetUserRolesResponse } from '../types';
import { getSiteList } from 'src/features/access/api';

const APPROVED_USER_STATUS = 'APPROVED';

const getUserRoles = async (): Promise<string[]> => {
  const response = await OrchestratorApiService.get(BACKEND_PATHS.USER_ROLES);
  const data: GetUserRolesResponse = response.data;
  const siteList = new Set<string>();
  data.userRoles.forEach((roleInfo) => {
    if (roleInfo.status === APPROVED_USER_STATUS) {
      if (roleInfo.attributes?.SiteId) {
        siteList.add(roleInfo.attributes.SiteId);
      }
    }
  });
  return Array.from(siteList).sort();
};

type QueryFnType = typeof getUserRoles;

export const useUserRoles = (isSystemAdmin: boolean | null) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['userRoles'],
    queryFn: () => (isSystemAdmin ? getSiteList() : getUserRoles()),
  });
};

export default useUserRoles;
