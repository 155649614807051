import { ExtractFnReturnType } from 'src/lib/react-query';
import { useQuery } from '@tanstack/react-query';
import { OrchestratorApiService } from 'src/service';
import { BACKEND_PATHS } from 'src/constants/backend-paths';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getSiteList } from './get-site-list';
import { getSiteAdminSites } from 'src/features/utils/access-util';

const getUserRoles = async (checkCountry: boolean): Promise<string[]> => {
  const siteAdminSiteList = await getSiteAdminSites();
  if (checkCountry) {
    const country = await fetchAuthSession().then(
      (authSession) => authSession.tokens?.idToken?.payload['custom:country'],
    );
    if (country) {
      const sitesResponse = await OrchestratorApiService.get(
        BACKEND_PATHS.GET_SITES_AND_LANGUAGES_CONFIG(country as string),
      );

      const sitesInCountry: string[] = sitesResponse.data.config[0].sites;
      return sitesInCountry.filter((site) => siteAdminSiteList.has(site));
    }
    return [];
  } else return Array.from(siteAdminSiteList).sort();
};

type QueryFnType = typeof getUserRoles;

export const useUserSites = (
  isSystemAdmin: boolean | null,
  isSiteAdmin: boolean | null,
  checkCountry = false,
) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['userRoles', isSystemAdmin],
    queryFn: () => (isSystemAdmin ? getSiteList() : getUserRoles(checkCountry)),
    enabled: isSiteAdmin || isSystemAdmin || false,
  });
};

export default useUserSites;
