import { create } from 'zustand';

interface IUserAuthorizationStore {
  isInternal: boolean | null;
  isSiteAdmin: boolean | null;
  isSystemAdmin: boolean | null;
  isSupplierManager: boolean | null;
  isPurchaseOrderAuditor: boolean | null;
  setUserDetails(
    isInternal: boolean,
    isSiteAdmin: boolean,
    isSystemAdmin: boolean,
    isSupplierManager: boolean,
    isPurchaseOrderAuditor: boolean,
  ): void;
}

export const useAuthorizationStore = create<IUserAuthorizationStore>((set) => ({
  isInternal: null,
  isSiteAdmin: null,
  isSystemAdmin: null,
  isSupplierManager: null,
  isPurchaseOrderAuditor: null,
  setUserDetails: (
    isInternal,
    isSiteAdmin,
    isSystemAdmin,
    isSupplierManager,
    isPurchaseOrderAuditor,
  ) => {
    set({
      isInternal,
      isSiteAdmin,
      isSystemAdmin,
      isSupplierManager,
      isPurchaseOrderAuditor,
    });
  },
}));
